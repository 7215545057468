import { gql } from '@apollo/client';

export const GET_ONBOARDER = gql`
	query Onboarder {
		onboarder {
			_id
			createdAt
			email
			name
			phone_number
			updatedAt
			schools {
				_id
				email_id
				createdAt
				name
				number_of_sections
				number_of_students
				phone_number
				student_count
				leed {
					_id
					onboarder_id
					status
				}
			}
		}
	}
`;

export const ONBOARDER_SCHOOL_LOGIN = gql`
	mutation Onboarder_school_login($school_id: String!) {
		onboarder_school_login(school_id: $school_id)
	}
`;

export const GET_SCHOOLS_PARENTS = gql`
	query Onboarder {
		onboarder {
			_id
			schools {
				_id
				parents {
					_id
					status
					phone_number
					student {
						name
					}
				}

				name
			}
		}
	}
`;

export const UPDATE_LEED_STATUS = gql`
	mutation Update_leed_status($leed_id: String!, $status: String!) {
		update_leed_status(leed_id: $leed_id, status: $status)
	}
`;

export const SEND_ONBOARDING_MASSAGE_TO_PARENTS = gql`
	mutation SendOnboardingMessage($school_id: String!) {
		sendOnboardingMessage(school_id: $school_id)
	}
`;
