import React from 'react';
import { Form, Input } from 'edviron-ui-package';
import { Navigate, useNavigate } from 'react-router-dom';
import { useMutation } from '@apollo/client';

import './login.css';
import { LOG_IN_AS_ONBOARDER } from './Qurries';
function Login({ user, set_user }: any) {
	const [LoginAsOnboarder] = useMutation(LOG_IN_AS_ONBOARDER);
	const navigate = useNavigate();
	if (user) return <Navigate to='/'></Navigate>;
	return (
		<div className='login-page min-h-screen flex w-full'>
			<div className='relative z-10 flex flex-1 flex-col bg-white py-10 px-4 shadow sm:justify-center md:flex-none md:px-28'>
				<div className='mx-auto  max-w-md sm:px-4 md:w-96 md:max-w-lg md:px-0'>
					<div className='flex flex-col'>
						<a aria-label='Home' href='/'>
							<div className='logo'>{/* <img src={Logo} alt="logo" /> */}</div>
						</a>

						<div className='mt-8'>
							<h2 className='text-lg font-semibold text-gray-900'>Sign in to your account</h2>
						</div>
					</div>

					<div className='mt-5 grid grid-cols-1 gap-y-0'>
						<Form
							onSubmit={async (data: any) => {
								const res = await LoginAsOnboarder({
									variables: {
										email: data['Email'],
										password: data['Password'],
									},
								});
								localStorage.setItem('token', await res.data.login_as_onboarder);
								set_user(await res.data.login_as_onboarder);
								navigate('/');
							}}
						>
							{/* Email ID */}
							<Input name='Email' placeholder='enter email' add_error={{}} type={'email'} required />
							<Input name='Password' placeholder='enter password' add_error={{}} type={'password'} required />

							{/* Sign In Button */}
							<div>
								<button className=' my-2 group inline-flex items-center justify-center rounded-full py-3 px-4 text-sm font-semibold focus:outline-none focus-visible:outline-2 focus-visible:outline-offset-2 bg-blue-600 text-white hover:text-slate-100 hover:bg-blue-500 active:bg-blue-800 active:text-blue-100 focus-visible:outline-blue-600 w-full'>
									Login
								</button>
							</div>
						</Form>
					</div>
				</div>
			</div>
		</div>
	);
}

export default Login;
