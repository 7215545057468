import React, { useState } from 'react';
import { NestedSidebarItem, Sidebar, SidebarItem } from 'edviron-ui-package';
import { AiFillHome } from 'react-icons/ai';
import { BiLogInCircle } from 'react-icons/bi';
import { FaSchool } from 'react-icons/fa';
import { Route, Routes, Link, Navigate } from 'react-router-dom';
import School from './components/School/School';
import ParentsList from './components/School/pages/ParentsList';
function Dashboard({ user, set_user }: any) {
	const [menu, setMenu] = useState(true);
	if (!user) return <Navigate to='/login'></Navigate>;
	return (
		<div className='dashboard flex w-full'>
			<div className='h-full'>
				<Sidebar schoolName={'Edviron'} Link={Link} menu={menu} setMenu={setMenu}>
					<SidebarItem icon={<AiFillHome className='text-2xl' />} name='Dashboard' to='/' />
					<SidebarItem icon={<FaSchool className='text-2xl' />} name='School' to='/school' />
					<SidebarItem
						className='mt-auto'
						icon={<BiLogInCircle className='text-2xl' />}
						name='Log Out'
						onTap={() => {
							localStorage.removeItem('token');
							set_user(null);
						}}
					/>
				</Sidebar>
			</div>

			<div
				className={
					'flex flex-col overflow-hidden flex-1 w-full transition-all duration-200 bg-[#F4F7FE] ' +
					(menu ? 'md:pl-72 pl-64' : ' pl-20')
				}
			>
				<div className='flex w-full min-h-screen sticky top-0 z-10'>
					<Routes>
						<Route path='/' element={<div> Dashboard </div>} />
						<Route path='/school/*' element={<School />} />
						{/* <Route path='/school/parents' element={<ParentsList />} /> */}
					</Routes>
				</div>
			</div>
		</div>
	);
}

export default Dashboard;
