/* eslint-disable react/jsx-pascal-case */
import React from 'react';
import { _Table } from 'edviron-ui-package';
import { useMutation, useQuery } from '@apollo/client';
import { GET_ONBOARDER, ONBOARDER_SCHOOL_LOGIN } from '../Queries';
import { Link } from 'react-router-dom';
function Schools() {
	const { data, loading, error } = useQuery(GET_ONBOARDER);
	const [onboarder_school_login] = useMutation(ONBOARDER_SCHOOL_LOGIN);
	if (loading) return <div>Loading...</div>;
	return (
		<div className='w-full p-4'>
			{data && (
				<_Table
					heading={'School List'}
					data={[
						['School Name', 'School Id', 'Email Id', 'Status', 'Action'],
						...data?.onboarder?.schools.map((d: any) => [
							<Link to={`/school/parents`} state={{ school: d }}>
								<span className='text-violet-600 font-bold'>{d?.name || 'Name'}</span>
							</Link>,
							d?._id,
							<span className='lowercase'>{d?.email_id}</span>,
							d?.leed?.status,
							<button
								className='px-4 py-1 bg-violet-600 text-white font-semibold rounded-lg'
								onClick={async () => {
									const res = await onboarder_school_login({
										variables: {
											school_id: d?._id,
										},
									});
									const token = await res?.data?.onboarder_school_login;
									if (token) {
										return window.open(process.env.REACT_APP_SCHOOL_DASHBOARD_URL + '?token=' + token, '_blank');
									}
								}}
							>
								Login To School
							</button>,
						]),
					]}
				/>
			)}
		</div>
	);
}

export default Schools;
