/* eslint-disable react/jsx-pascal-case */
import React, { useState } from 'react';
import { Select, _Table } from 'edviron-ui-package';
import PiaChart from '../components/PiaChart';
import { useMutation, useQuery } from '@apollo/client';
import { GET_ONBOARDER, GET_SCHOOLS_PARENTS, SEND_ONBOARDING_MASSAGE_TO_PARENTS, UPDATE_LEED_STATUS } from '../Queries';
import { useLocation } from 'react-router-dom';
import Pagination from '../../../../../components/Pagination/Pagination';
import { toast } from 'react-toastify';
function ParentsList() {
	const { data, loading, error } = useQuery(GET_SCHOOLS_PARENTS);
	const [update_leed_status] = useMutation(UPDATE_LEED_STATUS, { refetchQueries: [{ query: GET_ONBOARDER }] });
	const [send_massage_to_parents] = useMutation(SEND_ONBOARDING_MASSAGE_TO_PARENTS);
	let { state } = useLocation();
	//state?.school?.leed?._id;
	const school = data?.onboarder?.schools?.filter((school: any) => school._id === state.school._id);
	// console.log(school[0].parents.length);

	const [currentPage, setCurrentPage] = useState(1);
	const [leedStatus, setLeedStatus] = useState({
		name: state?.school?.leed?.status || 'Leed',
	});

	const totalSudents = loading ? 0 : school[0]?.parents?.length;
	const perPage = 20;
	const totalPages = Math.ceil(totalSudents / perPage);
	const indexOfLastItem = currentPage * perPage;
	const indexOfFirstItem = indexOfLastItem - perPage;
	const currentItems = loading ? 0 : school[0]?.parents.slice(indexOfFirstItem, indexOfLastItem);
	if (loading) return <div>Loading...</div>;

	return (
		<div className='px-3'>
			<div className='mb-8 bg-white rounded-lg p-4 w-full flex justify-between items-center'>
				<h2 className=' text-4xl font-bold text-[#2B3674]'>{state?.school?.name || 'School'}</h2>
				<div className='flex items-center space-x-3'>
					{state?.school?.leed && (
						<Select
							label='Update Leed Status'
							className=' max-w-[15rem] w-full inline-flex'
							selected={leedStatus}
							setSelected={async (e: any) => {
								setLeedStatus(e);

								update_leed_status({
									variables: {
										leed_id: state?.school?.leed?._id,
										status: e.name,
									},
								});
								toast.success('Status Updated..');
							}}
							options={[
								'CREDENTIALS_SEND',
								'DATABASE_UPLOADED',
								'FEE_STRUCTURE_UPLOADED',
								'ONBOARDED',
								'READY_FOR_ONBOARDING',
							].map((status: any) => {
								return { name: status };
							})}
						/>
					)}
					{school[0]?.parents?.length > 0 && (
						<button
							onClick={() => {
								send_massage_to_parents({
									variables: {
										school_id: state?.school._id,
									},
								});
							}}
							className='bg-[#4318FF] mt-8 max-w-fit w-full shrink-0 px-4 py-1.5 rounded-lg text-white '
						>
							Send Onboarding Message
						</button>
					)}
				</div>
			</div>
			<PiaChart massageData={data?.onboarder?.schools[0]?.parents} />
			{school[0]?.parents.length > 0 && (
				<_Table
					heading={'Students List'}
					data={[
						['Name', 'Phone Number', 'Message Status'],
						...currentItems.map((d: any) => [
							<div>{d.student?.name}</div>,

							<div>{d?.phone_number}</div>,
							<div
								className={
									'px-4 Capitalize rounded-full py-1  max-w-fit ' +
									(d.status === 'read'
										? ' bg-[#e6faee] text-[#1a9a3d]'
										: d.status === 'delivered'
										? '  bg-[#ecfbfd] text-[#04788a]'
										: d.status === 'NOT_SENT'
										? '  bg-[#fff3f0] text-[#863e2b]'
										: ' bg-[#f7f7f7] text-[#6b6c7d]')
								}
							>
								● {d.status}
							</div>,
						]),
					]}
					footer={totalPages && <Pagination page={currentPage} setPage={setCurrentPage} totalPageNo={totalPages} />}
				/>
			)}
		</div>
	);
}

export default ParentsList;
