/* eslint-disable react/jsx-pascal-case */
import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Schools from './pages/Schools';
import ParentsList from './pages/ParentsList';

function School() {
	return (
		<div className='w-full p-4'>
			<Routes>
				<Route path='/' element={<Schools />} />
				<Route path='/parents' element={<ParentsList />} />
			</Routes>
		</div>
	);
}

export default School;
