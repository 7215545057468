import React, { useState } from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Pie } from 'react-chartjs-2';

ChartJS.register(ArcElement, Tooltip, Legend);
function PiaChart({ massageData }: any) {
	const SENT = massageData?.filter((v: any) => v.status === 'SENT')?.length;
	const NOTSENT = massageData?.filter((v: any) => v.status === 'NOT_SENT')?.length;
	const data = {
		labels: ['SENT', 'NOT_SENT'],
		datasets: [
			{
				label: 'Massage Status',
				data: [SENT, NOTSENT],
				backgroundColor: ['rgba(67, 24, 255, 1)', 'rgba(106, 210, 255, 1)', 'rgba(239, 244, 251, 1)'],
				borderColor: ['rgba(67, 24, 255, 1)', 'rgba(106, 210, 255, 1)', 'rgba(239, 244, 251, 1)'],
				borderWidth: 1,
			},
		],
	};
	return (
		<div className='bg-white shadow rounded-3xl w-96 p-8 mb-6'>
			<h2 className='font-bold text-[20px] mb-4'>Massage Status</h2>
			<div className='flex justify-center'>
				<div className='w-60 h-60'>
					<Pie data={data} />
				</div>
			</div>
		</div>
	);
}

export default PiaChart;
